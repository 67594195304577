import React from 'react';
import Form from './Components/Form'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '0px',
  background: 'transparent'
}));

function App() {
  return (
    <div className="App">
      <div className="background">
        <div className="cover">
          <div className="form">
            <Form/>
          </div>
        </div>
      </div>
    </div>
);
}

export default App;
