import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useState} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import translations from "../translations";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Swal from 'sweetalert2'
import emailjs, {init} from '@emailjs/browser'

init("user_ZKftQ5FcC9QLVtYLvIMon");


const steps: ({
  ident: number,
  name: string,
  completed: boolean,
  inputs: Array<{name: string}>
})[] = [
  {
    ident: 0,
    name: 'socialInfo1',
    completed: false,
    inputs: [
      { name: 'facebook'},
      { name: 'instagram'},
      { name: 'youtube'},
    ]
  },
  {
    ident: 1,
    name: 'socialInfo2',
    completed: false,
    inputs: [
      { name: 'tiktok'},
      { name: 'linkedin'},
      { name: 'tripadvisor'},
    ]
  },
  {
    ident: 2,
    name: 'socialInfo3',
    completed: false,
    inputs: [
      { name: 'web'},
      { name: 'whatsapp'},
      { name: 'telegram'},
      { name: 'phone'},
    ]
  },
  {
    ident: 3,
    name: 'personalInfo',
    completed: false,
    inputs: [
      { name: 'address'},
      { name: 'fullname'},
      { name: 'workposition'},
      { name: 'workphone'},
    ]
  }
]

export default function HorizontalLinearStepper() {
  const [language, setLanguage] = useState('az');
  const [activeStep, setActiveStep] = useState(steps[0])
  const [formData, setFormData] = useState({
    facebook: "",
    instagram: "",
    phone: "",
    youtube: "",
    tiktok: "",
    linkedin: "",
    tripadvisor: "",
    web: "",
    whatsapp: "",
    telegram: "",
    address: "",
    fullname: "",
    workposition: "",
    workphone: "",
  })

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };
  const handleComplete = async () : Promise<void> => {
    await Swal.fire(
      translations[language].successTitle,
      translations[language].successText,
      'success'
    )
    emailjs.send("service_9jelwnk","template_bfjdlvs", formData)
      .then(function(response) {
      console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
      console.log('FAILED...', error);
    });

    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => { location.reload() }, 5000)
  }
  const handleNext = () => {
    let currentStepIndex : number = steps.indexOf(activeStep)
    if(currentStepIndex + 1 !== steps.length) {
      setActiveStep(steps[++currentStepIndex]);
    }
  };
  const handleBack = () => {
    let currentStepIndex : number = steps.indexOf(activeStep)
    let nextStepIndex: number = --currentStepIndex

    setActiveStep(steps[nextStepIndex]);
  };

  return (
    <Box sx={{ width: '90%', marginLeft: '5%' }}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="demo-simple-select-label">{ translations[language].language }</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={translations[language].language}
            onChange={handleChange}
          >
            <MenuItem value="az">Azərbaycan</MenuItem>
            <MenuItem value="ru">Русский</MenuItem>
            <MenuItem value="en">English</MenuItem>
          </Select>
        </FormControl>

        <div className="hide-on-mobile-devices">
          <Stepper activeStep={activeStep.ident}>
            {steps.map((step, index) => {
              return (
                <Step key={step.name} {...step}>
                  <StepLabel>{translations[language][step.name]}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <React.Fragment>

          { activeStep.inputs.map((input, index) => {
            return <TextField
              id="outlined-basic"
              key={input.name}
              label={translations[language][input.name]}
              variant="outlined"
              margin="normal"
              fullWidth={true}
              onChange={(e) => setFormData({...formData, [input.name]: e.target.value})}
            />
          }) }

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant="outlined"
              color="inherit"
              disabled={activeStep.ident === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              <ArrowBackIcon/>
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="outlined" onClick={handleNext}>
              {
                activeStep.ident === steps.length - 1 ?
                <span onClick={handleComplete}>{ translations[language].complete }</span> : <ArrowForwardIcon/>
              }
            </Button>
          </Box>


        </React.Fragment>
    </Box>
  );
}
