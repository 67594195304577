const translations: any = {
  az: {
    language: 'Dil',
    next: 'Irəli',
    back: 'Geriyə',
    complete: 'Tamamla',
    personalInfo: 'Şəxsi məlumatlar',
    socialInfo1: 'Sosial məlumatları',
    socialInfo2: 'Sosial məlumatları',
    socialInfo3: 'Sosial məlumatları',
    phone: 'Telefon nömrəsi',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube',
    tiktok: 'Tiktok',
    linkedin: 'Linkedin',
    tripadvisor: 'Tripadvisor',
    web: 'Web səhifə',
    whatsapp: 'Whatsapp nömrə',
    telegram: 'Telegram nömrə',
    address: 'Ünvan və ya geo yerləşmə',
    fullname: 'Tam ad',
    workposition: 'Vəzifə',
    workphone: 'İş və ya ev telefonu',
    successTitle: 'Təşəkkür edirik!',
    successText: 'Sizin müraciətiniz göndərildi!',
  },
  ru: {
    language: 'Язык',
    next: 'Далее',
    back: 'Назад',
    complete: 'Завершить',
    personalInfo: 'Личная информация',
    socialInfo1: 'Социальная информация',
    socialInfo2: 'Социальная информация',
    socialInfo3: 'Социальная информация',
    phone: 'Номер телефона',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube',
    tiktok: 'Tiktok',
    linkedin: 'Linkedin',
    tripadvisor: 'Tripadvisor',
    web: 'Веб сайт',
    whatsapp: 'Whatsapp номер',
    telegram: 'Telegram никнейм',
    address: 'Адрес или геолокация',
    fullname: 'Полное имя',
    workposition: 'Специальность',
    workphone: 'Домашний или Рамбочий телефон',
    successTitle: 'Спасибо!',
    successText: 'Ваше обращение отправлено!',
  },
  en: {
    language: 'Language',
    next: 'Next',
    back: 'Back',
    complete: 'Complete',
    personalInfo: 'Personal info',
    socialInfo1: 'Social info',
    socialInfo2: 'Social info',
    socialInfo3: 'Social info',
    phone: 'Phone number',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube',
    tiktok: 'Tiktok',
    linkedin: 'Linkedin',
    tripadvisor: 'Tripadvisor',
    web: 'Web site',
    whatsapp: 'Whatsapp number',
    telegram: 'Telegram nickname',
    address: 'Address or geolocation',
    fullname: 'Fullname',
    workposition: 'Work position',
    workphone: 'Work or home phone',
    successTitle: 'Good job!',
    successText: 'Your offer have been sent!',
  }
}

export default translations